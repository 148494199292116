console.log('\'Allo \'Allo!');

// Uncomment to enable Bootstrap tooltips
// https://getbootstrap.com/docs/4.0/components/tooltips/#example-enable-tooltips-everywhere
$(function () { $('[data-toggle="tooltip"]').tooltip(); });

// Uncomment to enable Bootstrap popovers
// https://getbootstrap.com/docs/4.0/components/popovers/#example-enable-popovers-everywhere
 $(function () { $('[data-toggle="popover"]').popover(); });
jQuery(function() {
  $('#header').vegas({
    transition: 'zoomOut2',
    timer: false,
    transitionDuration: 500,
    /*slides: [
      {src: 'img/slides/home.jpg'},
      {src: 'img/slides/criacao-sites.jpg'},
      {src: 'img/slides/links-patrocinados.jpg'},
      {src: 'img/slides/social-marketing.jpg'},
      {src: 'img/slides/mobile.jpg'}
    ]*/
    slides: slidesData
  });
});